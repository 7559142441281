import createCorrelationId from '../utils/createCorrelationId';

export function getCorrelationId() {
  if (typeof window === 'undefined') {
    return createCorrelationId();
  }

  if (!window.correlationId) {
    window.correlationId = createCorrelationId();
  }

  return window.correlationId;
}
